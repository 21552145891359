import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { datadogRum } from '@datadog/browser-rum';

/**
 * Initial page load
 * Forcing redeploy
 * @returns {JSX.Element}
 * @constructor
 */
export default function Index() {
    const router = useRouter();

    useEffect(() => {
        router
            .replace({
                pathname: '/home'
            })
            .then();
    }, [router]);

    return <nav />;
}

datadogRum.init({
    applicationId: process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID,
    clientToken: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: 'pwa-ayoba-lite',
    env: process.env.NEXT_PUBLIC_DATADOG_ENVIRONMENT,
    version: '0.1.0', //TODO set dynamically
    sessionSampleRate: Number(process.env.NEXT_PUBLIC_DATADOG_SESSION_SAMPLE_RATE),
    sessionReplaySampleRate: Number(process.env.NEXT_PUBLIC_DATADOG_SESSION_REPLAY_SAMPLE_RATE),
    trackUserInteractions: process.env.NEXT_PUBLIC_DATADOG_TRACK_USER_INTERACTIONS === 'true',
    trackResources: process.env.NEXT_PUBLIC_DATADOG_TRACK_RESOURCES === 'true',
    trackLongTasks: process.env.NEXT_PUBLIC_DATADOG_TRACK_LONG_TASKS === 'true',
    defaultPrivacyLevel: process.env.NEXT_PUBLIC_DATADOG_DEFAULT_PRIVACY_LEVEL,
});